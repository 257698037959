import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'
import PricesMixin from '~/mixins/PricesMixin'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductBoxMixin from '~/mixins/ProductBoxMixin'
import ProductDateOfStorage from '@theme/components/product/ProductDateOfStorage'
import ProductImpressionMixin from '~/mixins/ProductImpressionMixin'
import TitleHighlightMixin from '~/mixins/TitleHighlightMixin'

export default {
  mixins: [HeadingLevel, PricesMixin, ProductBoxMixin, TitleHighlightMixin, ProductImpressionMixin],
  components: {
    AppImage,
    AppTitle,
    ProductAvailability,
    ProductDateOfStorage,
  },
  computed: {
    itemFormattedPrices() {
      return this.getFormattedPrices({
        prices: this.activeVariant.prices,
      })
    },
  },
}
