import AppTitle from '@theme/components/atom/AppTitle'
import Loader from '@theme/components/utils/Loader'
import ProductTeaser from '@theme/components/product/ProductTeaser'
import TextHighlight from 'vue-text-highlight'
import _ from 'lodash'

export default {
  components: {
    AppTitle,
    ProductTeaser,
    TextHighlight,
    Loader,
  },
  props: {
    hide: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    hasAnyContent() {
      return this.$themeSettings.components.PageCatalog.searchThrough.some(
        item => !!_.get(this.searchResults, `${item}.total`),
      )
    },
    searchResults() {
      return this.$store.state.search.dropdown.results
    },
  },
}
